import { between, down, up } from "styled-breakpoints"
import styled from "styled-components"

const Input = styled.input`
  ${down("md")} {
    height: 4.8rem;
  }

  height: 6.4rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;

  &::placeholder {
    opacity: 0.7;
  }

  ${props => ({
    fontWeight: "normal",
    fontSize: "1.7rem",
    lineHeight: "2.4rem",
    color: props.theme.colors.text,
    fontFamily: props.theme.fonts.azoSans,

    [between("md", "xl")(props)]: {
      fontSize: "2rem",
      lineHeight: "3rem",
    },

    [up("xl")(props)]: {
      fontSize: "2.4rem",
      lineHeight: "3.7rem",
    },

    [up("xxl")(props)]: {
      fontSize: "3.4rem",
      lineHeight: "5rem",
    },
  })}

  &:focus-visible {
    outline: solid 1px ${({ theme }) => theme.colors.white} !important;
    outline-offset: 0.5rem;
  }
`

export default Input
