import { Link } from "gatsby"
import React from "react"
import Divider from "../divider"
import { FlexColumn } from "../system/flex-column"
import { FlexRow } from "../system/flex-row"
import { Typography } from "../system/typography"
import * as Styled from "./styles"

type Props = {
  excerpt: string
  title: string
  term: string
  link: string
}

const SearchResult: React.FC<Props> = ({ excerpt, title, term, link }) => {
  return (
    <>
      <FlexRow as="article" flexWrap={{ xs: "wrap", lg: "initial" }}>
        <FlexColumn flex={5} flexBasis={{ xs: "100%", lg: "min-content" }}>
          <Styled.Excerpt
            dangerouslySetInnerHTML={{
              __html: excerpt.replaceAll(
                new RegExp(
                  term
                    .replaceAll(/[\[\\\^\$\.\|\?\*\+\(\)]/g, m => `\\${m}`)
                    .trim(),
                  "gi"
                ),
                match => `<mark>${match}</mark>`
              ),
            }}
          />
        </FlexColumn>
        <FlexColumn flex={1} />
        <FlexColumn
          flex={2}
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
          mt={{ xs: "6rem", lg: 0 }}
        >
          <Typography
            as={Link}
            variant="caption"
            m="0 !important"
            style={{ textAlign: "end" }}
            to={link}
          >
            {title} <Styled.Arrow />
          </Typography>
        </FlexColumn>
      </FlexRow>
      <Divider variant="blue" my={{ xs: "4rem", lg: "6rem" }} />
    </>
  )
}

export default SearchResult
