import styled from "styled-components"
import ArrowRight from "../../images/arrow-right.inline.svg"
import { Typography } from "../system/typography"

export const Arrow = styled(ArrowRight)`
  height: 1em;
  width: auto;
  vertical-align: baseline;

  & path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`

export const Excerpt = styled(Typography).attrs({
  variant: "body",
  m: "0 !important",
})`
  & mark {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: bold;
  }
`
