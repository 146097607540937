import { graphql, PageProps } from "gatsby"
import React, { useMemo, useState } from "react"
import { ThemeProvider } from "styled-components"
import AdvancedHeader from "../components/header/advanced"
import Seo from "../components/seo"
import GlobalStyles from "../styles/globals"
import { blueTheme, lightTheme } from "../styles/themes"
import * as Styled from "../styles/pages/search"
import PageHeading from "../components/page-heading"
import Input from "../components/input"
import { FlexRow } from "../components/system/flex-row"
import { FlexColumn } from "../components/system/flex-column"
import { Typography } from "../components/system/typography"
import { useFormik } from "formik"
import * as yup from "yup"
import SearchResult from "../components/search-result"
import Divider from "../components/divider"
import { AnimatePresence, motion } from "framer-motion"
import ScrollUpCTA from "../components/ScrollUpCTA"
import ContinueLink from "../components/continue-link"
import {
  create,
  insert,
  search,
  SearchResult as Result,
} from "@lyrasearch/lyra"
import visit from "unist-util-visit"
import { toString } from "../util/toString"
// import Checkbox from "../components/checkbox"

interface Data {
  allMdx: {
    nodes: Array<{
      id: string
      slug: string
      mdxAST: any
      frontmatter: {
        index: number
        title: string
      }
      fields: {
        headings: Array<{
          id: string
          value: string
          depth: number
        }>
      }
    }>
  }
}

const SearchPage: React.FC<PageProps<Data>> = ({ data }) => {
  const [searchPhrase, setSearchPhrase] = useState("")
  const formik = useFormik({
    initialValues: { query: "", mdxOnly: false },
    validationSchema: yup.object({
      query: yup.string().required().min(3),
      mdxOnly: yup.boolean(),
    }),
    onSubmit: ({ query, mdxOnly }) => {
      setSearchPhrase(query)

      if (`gtag` in window && typeof window.gtag === "function") {
        window.gtag(`event`, `search`, { search_query: query })
      }
    },
  })

  const db = useMemo(() => {
    const database = create({
      schema: {
        text: "string",
        uid: "string",
        link: "string",
        title: "string",
      },
    })

    for (const node of data.allMdx.nodes) {
      let title = `Rozdział ${node.frontmatter.index}`

      if (node.frontmatter.title) {
        if (
          !node.frontmatter.index ||
          node.frontmatter.index.toFixed(0) === "0"
        ) {
          title = node.frontmatter.title
        } else {
          title = title.concat(` / ${node.frontmatter.title}`)
        }
      }

      for (const heading of node.fields.headings) {
        insert(database, {
          uid: heading.id,
          link: `/${node.slug}#${heading.id}`,
          text: heading.value,
          title,
        })
      }

      visit(node.mdxAST, ["paragraph"], mdxNode => {
        const text = toString(mdxNode, { includeImageAlt: false })
        if (!text) return

        const uid = `paragraph__${mdxNode.position?.start.line}`
        const link = `/${node.slug}#${uid}`
        insert(database, { uid, link, text, title })
      })
    }

    return database
  }, [data])

  const results = useMemo(() => {
    return search(db, {
      term: searchPhrase,
      properties: ["text"],
      limit: 1000,
    })
  }, [db, searchPhrase])

  return (
    <ThemeProvider theme={blueTheme}>
      <Seo title="Wyszukiwanie" />
      <GlobalStyles variant="blue" />
      <AdvancedHeader divider progress={<ContinueLink />} />
      <Styled.Main>
        <Styled.Layout as="section">
          <PageHeading>Wyszukiwanie</PageHeading>
          <Styled.Spacer />
          <Styled.Content>
            <FlexRow as="form" onSubmit={formik.handleSubmit}>
              <FlexColumn flex="1 0 auto">
                <Input
                  id="query"
                  name="query"
                  type="text"
                  placeholder="Wpisz wyszukiwaną frazę"
                  aria-label="Wyszukiwana fraza:"
                  aria-errormessage="error"
                  aria-invalid={!!formik.errors.query}
                  aria-required="true"
                  value={formik.values.query}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.query && formik.touched.query && (
                  <Typography
                    variant="captionSmall"
                    id="error"
                    role="alert"
                    mt="2rem !important"
                  >
                    Wpisz przynajmniej 3 znaki.
                  </Typography>
                )}
                {/* // ! Disabled due to no way of getting html pages content */}
                {/* <Checkbox
                  labelProps={{ style: { marginTop: "6rem" } }}
                  inputProps={{
                    name: "mdxOnly",
                    checked: formik.values.mdxOnly,
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                  }}
                >
                  <span>
                    Tylko tekst książki{" "}
                    <Styled.NoAdditional>
                      (bez stron dodatkowych)
                    </Styled.NoAdditional>
                  </span>
                </Checkbox> */}
              </FlexColumn>
              <FlexColumn>
                <Styled.SubmitButton type="submit" disabled={!formik.isValid}>
                  Szukaj <Styled.SearchIcon />
                </Styled.SubmitButton>
              </FlexColumn>
            </FlexRow>
            {searchPhrase && (
              <Styled.SearchAbstract>
                Wyszukiwano{" "}
                <Styled.SearchPhrase>{searchPhrase}</Styled.SearchPhrase>.
                Znaleziono w {results.hits.length} miejscach.
              </Styled.SearchAbstract>
            )}
          </Styled.Content>
        </Styled.Layout>
        <ThemeProvider theme={lightTheme}>
          <AnimatePresence exitBeforeEnter>
            <MeoResults searchPhrase={searchPhrase} results={results} />
          </AnimatePresence>
        </ThemeProvider>
      </Styled.Main>
    </ThemeProvider>
  )
}

export default SearchPage

const Results: React.FC<{
  searchPhrase: string
  results: Result<{
    text: "string"
    uid: "string"
    link: "string"
    title: "string"
  }>
}> = ({ searchPhrase, results }) => {
  if (!searchPhrase || results.count === 0) return null

  const y = getWindowHeight()

  return (
    <Styled.ResultsLayout
      as={motion.section}
      key={searchPhrase}
      initial={{ y }}
      animate={{ y: 0 }}
      exit={{ y }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <Styled.Spacer />
      <Styled.Content m="0 !important">
        {results.hits.map(hit => (
          <SearchResult
            key={hit.id}
            title={hit.document.title}
            excerpt={hit.document.text}
            link={hit.document.link}
            term={searchPhrase}
          />
        ))}
        <ScrollUpCTA />
      </Styled.Content>
      <Divider variant="blue" mt="6rem" mb={{ xs: "5rem", lg: "7rem" }} />
    </Styled.ResultsLayout>
  )
}

const MeoResults = React.memo(Results)

const getWindowHeight = () => {
  if (typeof window === "undefined") return 1000
  return window.innerHeight
}

export const query = graphql`
  query {
    allMdx {
      nodes {
        id
        slug
        mdxAST
        frontmatter {
          index
          title
        }
        fields {
          headings {
            value
            id
          }
        }
      }
    }
  }
`
