import { between, down, up } from "styled-breakpoints"
import styled from "styled-components"
import Button from "../../components/button"
import { FlexColumn } from "../../components/system/flex-column"
import { FlexRow } from "../../components/system/flex-row"
import { Typography } from "../../components/system/typography"
import ArrowUp from "../../images/arrow-up.inline.svg"
import MagnifyingGlass from "../../images/magnifying-glass.inline.svg"

export const Main = styled.main`
  min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.blue};
`

export const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  padding: 0 1.8rem;

  ${between("md", "xl")} {
    padding: 0 3.5rem;
  }

  ${between("xl", "xxl")} {
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`

export const Spacer = styled(FlexColumn)`
  display: none;
  flex: 1;
  padding-left: 5vw;

  ${up("lg")} {
    display: initial;
    flex: 4;
  }
`

export const Content = styled(FlexColumn)`
  flex: 1;
  margin-top: 6.8rem;
  padding-right: 5vw;
  padding-left: 5vw;

  ${up("lg")} {
    flex: 8;
    margin-top: 7.5rem;
    padding-left: 0;
  }
`

export const SearchIcon = styled(MagnifyingGlass)`
  height: 0.8em;
  margin-left: 1rem;
`

export const SubmitButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 0.75rem;

  ${down("md")} {
    height: 4.8rem;
    margin-top: 3.4rem;
    margin-left: 0;
  }

  &:hover,
  &:focus-visible,
  &:active {
    ${SearchIcon} {
      circle:nth-child(2),
      line {
        transition: stroke 0.25s ease-in;
        stroke: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`

export const NoAdditional = styled.span`
  ${down("md")} {
    display: none;
  }
`

export const SearchAbstract = styled(Typography)`
  margin-top: 5rem;
  text-transform: uppercase;
  font-size: 1.4rem;

  ${between("md", "xl")} {
    font-size: 1.5rem;
  }

  ${up("xl")} {
    font-size: 2rem;
  }

  ${up("xxl")} {
    font-size: 3rem;
  }
`

SearchAbstract.defaultProps = {
  variant: "body",
  typo: "azo",
}

export const SearchPhrase = styled.span`
  font-style: italic;
`

export const ResultsLayout = styled(Layout)`
  background: ${({ theme }) => theme.colors.white};
  flex: 1 0 auto;
  margin-top: 6rem;
  padding-top: 4rem;

  ${up("lg")} {
    padding-top: 8rem;
  }
`

export const ScrolUpCTA = styled(FlexRow)`
  justify-content: flex-end;
  text-align: end;
  margin-top: 4rem;
  text-transform: uppercase;
`

export const Arrow = styled(ArrowUp)`
  & * {
    stroke: ${({ theme }) => theme.colors.blue};
    fill: ${({ theme }) => theme.colors.blue};
  }
`
